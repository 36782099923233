<template lang="pug">
.search
  LInput(
    v-model="query"
    @enter="search"
    @input="$emit('input', query)"
  )
    template(slot="right")
      .search__actions
        LIcon(
          v-if="hasQuery"
          name="close"
          color="#757373"
          @click="query = null; $emit('input', query)"
        )
        .search__delimiter(v-if="hasQuery")
        LIcon(name="search" color="#757373" @click="search")

</template>

<script>
export default {
  name: 'LSearch',
  components: {
    LInput: () => import(/* webpackChunkName: "ui" */ '@/components/ui/LInput'),
    LIcon: () => import(/* webpackChunkName: "ui" */ '@/components/ui/LIcon')
  },
  props: {
    value: {
      type: String,
      default: null
    },
    global: {
      type: Boolean,
      default: true
    }
  },
  data () {
    return {
      query: this.value
    }
  },
  computed: {
    hasQuery () {
      return this.query !== null && this.query.length > 0
    }
  },
  methods: {
    search () {
      if (!this.global) this.$emit('search')
      else {
        this.$router.push(`/app/search?query=${this.query}`)
      }
    }
  },
  watch: {
    value (newVal) {
      if (this.query !== newVal) this.query = newVal
      
    }
  }
}
</script>

<style lang="scss">
.search {
  & > .linput {
    height: 55px;
    border-radius: 5px;
    padding-right: 10px;
    padding-left: 10px;
    border: solid 1px #888A99;
    @media (min-width: 960px) {
      height: 30px;
      padding-left: 5px;
      padding-right: 5px;
    }
  }
  &__actions {
    display: flex;
    & > .licon {
      width: 24px !important;
      height: 24px !important;
      cursor: pointer;
      @media (min-width: 960px) {
        width: 18px !important;
        height: 18px !important;
      }
    }
  }
  &__delimiter {
    width: 1px;
    background-color: #757373;
    margin: 0 10px;
    @media (min-width: 768px) {
      margin: 0 5px;
    }
  }
}
</style>