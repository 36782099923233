<template lang="pug">
.header-signs
  .header-signs__notification(:class="{'header-signs__notification_show': showNotifications }" v-click-outside="hide")
    .header-signs__notification-icon(@click="show")
      LIcon(name="bell" color="#757373")
      .header-signs__notification-count(v-if="count > 0") {{ getNotificationCount }}

    .header-signs__notification-dropdown
      template(v-if="notificationsList.length > 0")
        h4 Оповещения
        .header-signs__notification-dropdown-item(v-for="notification, index in notificationsList" v-if="") 
          LAvatar(:url="notification.user.photo.preview" size="micro" @click="viewUser(notification.user.uuid)")
          .header-signs__notification-wrapper
            .header-signs__notification-wrapper-name {{ notification.user.name }}
            .header-signs__notification-wrapper-info
              template(v-if="notification.type === 'request-sent'")
                template(v-if="notification.payload.status === 'reject'") Запрос в друзья отклонён
                template(v-if="notification.payload.status === 'accept'") Запрос в друзья принят

              template(v-else) {{ $t(`${notification.type}`) }}
            .header-signs__notification-wrapper-buttons(v-if="hasButtons(notification.type)")
              template(v-if="notification.friendrequest === null")
                LButton(
                  theme='red'
                  size="micro"
                  @click="addFriend(index)"
                  :preloader="notification.accept_preloader"
                ) ДОБАВИТЬ
                LButton.ml-1(
                  theme='blue'
                  size="micro"
                  @click="removeFriend(index)"
                  :preloader="notification.reject_preloader"
                ) УДАЛИТЬ

              template(v-if="notification.friendrequest === 'accept'") Вы приняли дружбу
              template(v-if="notification.friendrequest === 'reject'") Вы отклонили запрос на дружбу

      template(v-else)
        p Нет оповещений

  LIcon.header-signs__messages(name="message" :color="messagesColor" @click="$router.push('/app/messages')")


</template>

<script>
// TODO i18n
import { mapActions } from 'vuex'

export default {
  name: 'HeaderNotification',
  created () {
    this.notifications()
    .then( result => {
      result.data.data.data.forEach(item => {
        if (item.payload?.status === 'request') {
          item['accept_preloader'] = false
          item['reject_preloader'] = false
          item['friendrequest'] = null
        }
      })
      this.notificationsList = result.data.data.data
      this.count = 0
      this.notificationsList.forEach(item => {
        if (item.status === 0) this.count += 1
      })
    })
  },
  components: {
    LIcon: () => import(/* webpackChunkName: "ui" */ '@/components/ui/LIcon'),
    LButton: () => import(/* webpackChunkName: "ui" */ '@/components/ui/LButton'),
    LAvatar: () => import(/* webpackChunkName: "ui" */ '@/components/ui/LAvatar'),
  },
  data () {
    return {
      showNotifications: false,
      notificationsList: [],
      count: 0,
      friendsRequestsList: [],
    }
  },
  computed: {
    getNotificationCount () {
      if (this.count > 9) return '9+'
      else return this.count
    },
    messagesColor () {
      return this.$route.name === "Messages" ? '#C91818' : '#757373'
    }
  },
  methods: {
    ...mapActions('user', ['notifications', 'acceptFriend', 'rejectFriend']),
    ...mapActions('posts', ['readNotification']),
    hide () {
      this.showNotifications = false
    },
    show () {
      this.showNotifications = !this.showNotifications
      if (this.count > 0) {
        this.count = 0;
        let uuidsArr = this.notificationsList.map(item => item.uuid)
        this.readNotification(uuidsArr)
        .then( res => {
          console.log(res)
        })
      }

    },
    hasButtons (type) {
      if (type === 'request-incoming') return true
      else return false
    },
    viewUser (uuid) {
      this.$router.push(`/app/user/${uuid}`)
    },
    removeFriend (index) {
      this.$set(this.notificationsList[index], 'reject_preloader', true)
      this.rejectFriend(this.notificationsList[index].payload['request-uuid'])
      .then(resolve => {
        this.$set(this.notificationsList[index], 'reject_preloader', false)
        this.$set(this.notificationsList, 'friendrequest', 'reject')
        this.notificationsList.splice(index, 1)
      })
    },
    addFriend (index) {
      this.$set(this.notificationsList[index], 'accept_preloader', true)
      this.acceptFriend(this.notificationsList[index].payload['request-uuid'])
      .then(resolve => {
        this.$set(this.notificationsList[index], 'accept_preloader', false)
        this.$set(this.notificationsList, 'friendrequest', 'accept')
        this.notificationsList.splice(index, 1)
      })
    }
  }
}
</script>


<style lang="scss">
.header-signs {
  display: flex;
  &__notification {
    position: relative;
    overflow: hidden;
    margin-right: 15px;
    &-wrapper {
      margin-left: 5px;
      &-name {
        font-family: Roboto;
        font-style: normal;
        font-weight: bold;
        font-size: 12px;
        line-height: 14px;
        margin-top: 3px;
      }
      &-info {
        font-size: 10px;
        margin-top: 2px;
      }
      &-buttons {
        display: flex;
        margin-top: 5px;
        & .lbutton {
          display: inline-flex;
        }
      }
    }
    &_show {
      overflow: visible;
      & .licon > path {
        fill: #C91818;
      }
    }
    &-icon {
      & .licon {
        width: 38px;
        height: 38px;
        cursor: pointer;
      }
    }
    &-count {
      width: 20px;
      height: 20px;
      border-radius: 50%;
      background: #C91818;
      position: absolute;
      display: flex;
      align-items: center;
      justify-content: center;
      color: #fff;
      font-weight: 700;
      top: 4px;
      left: 15px;
      border: solid 1px #fff;
      font-size: 12px;
      cursor: pointer;
    }
    &-dropdown {
      position: absolute;
      width: 286px;
      background: #FFFFFF;
      box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
      border-radius: 10px;
      padding: 28px;
      z-index: 1000;
      & h4 {
        font-size: 14px;
        padding-bottom: 10px;
      }
      &-item {
        display: flex;
        padding-bottom: 10px;
      }
    }
  }

  &__messages {
    width: 32px;
    height: 32px;
    cursor: pointer;
  }
}
</style>
