<template lang="pug">
.header-user(@click="$router.push('/app/profile')")
  p.header-user__name {{ getUserName }}
  LAvatar(size="small" :url="getUrl")

</template>

<script>
// TODO i18n
import { mapGetters } from 'vuex'
export default {
  name: 'HeaderNotification',
  data () {
    return {
    }
  },
  components: {
    LAvatar: () => import(/* webpackChunkName: "ui" */ '@/components/ui/LAvatar')
  },
  computed: {
    ...mapGetters('user', ['HAS_AUTH', 'USER_DATA', 'SETTINGS']),
    getUserName () {
      if (this.SETTINGS !== null) {
        return this.SETTINGS.name
      }
    },
    getUrl () {
      if (this.SETTINGS !== null) {
        return this.SETTINGS.photo.preview
      }
    }
  }
}
</script>

<style lang="scss">
.header-user {
  display: flex;
  align-items: center;
  &__name {
    margin-right: 10px;
    cursor: pointer;
    display: none;
    @media (min-width: 560px) {
      display: block;
    }
    &:hover {
      text-decoration: underline;
    }
  }
}
</style>
